import styles from './styles.scss';
import { inBetween } from './inBetween';
import templateHtml from './template.html';
import { TABINDEX, RENDERED_CLASS } from './defaults';
const templateElement = document.createElement('template');
templateElement.innerHTML = `<style>${styles}</style>${templateHtml}`;
const KeySlideOffset = {
    ArrowLeft: -1,
    ArrowRight: 1,
};
const getTouchPagePoint = (e) => ({
    x: e.touches[0].pageX,
    y: e.touches[0].pageY,
});
const slideAnimationPeriod = 1000 / 60;
export class HTMLImgComparisonSliderElement extends HTMLElement {
    constructor() {
        super();
        this.exposure = 50;
        this.isMouseDown = false;
        this.isFocused = false;
        this.onWindowMouseMove = (e) => {
            /**
             * This dynamic window.onmousemove event handler
             * registers on mousedown and removes on mouse up.
             * The whole mumbo-jumbo is needed to capture
             * mouse events outside of component. This provides
             * better user experience.
             */
            if (this.isMouseDown) {
                this.slideToPageX(e.pageX);
            }
        };
        this.bodyUserSelectStyle = '';
        this.onMouseDown = (e) => {
            window.addEventListener('mousemove', this.onWindowMouseMove);
            window.addEventListener('mouseup', this.onWindowMouseUp);
            this.isMouseDown = true;
            this.enableTransition();
            this.slideToPageX(e.pageX);
            this.focus();
            this.bodyUserSelectStyle = window.document.body.style.userSelect;
            window.document.body.style.userSelect = 'none';
        };
        this.onWindowMouseUp = () => {
            this.isMouseDown = false;
            window.document.body.style.userSelect = this.bodyUserSelectStyle;
            window.removeEventListener('mousemove', this.onWindowMouseMove);
            window.removeEventListener('mouseup', this.onWindowMouseUp);
        };
        this.isTouchComparing = false;
        this.hasTouchMoved = false;
        this.onTouchStart = (e) => {
            this.touchStartPoint = getTouchPagePoint(e);
            if (this.isFocused) {
                this.enableTransition();
                this.slideToPageX(e.touches[0].pageX);
            }
        };
        this.onTouchMove = (e) => {
            if (this.isTouchComparing) {
                this.slideToPageX(e.touches[0].pageX);
                e.preventDefault();
                return false;
            }
            if (!this.hasTouchMoved) {
                const currentPoint = getTouchPagePoint(e);
                if (Math.abs(currentPoint.y - this.touchStartPoint.y) <
                    Math.abs(currentPoint.x - this.touchStartPoint.x)) {
                    this.isTouchComparing = true;
                    this.focus();
                    this.slideToPageX(e.touches[0].pageX);
                    e.preventDefault();
                    return false;
                }
                this.hasTouchMoved = true;
            }
        };
        this.onTouchEnd = () => {
            this.isTouchComparing = false;
            this.hasTouchMoved = false;
        };
        this.onBlur = () => {
            this.stopSlideAnimation();
            this.isFocused = false;
        };
        this.onFocus = () => {
            this.isFocused = true;
        };
        this.onKeyDown = (e) => {
            if (this.isAnimating) {
                return;
            }
            this.isAnimating = true;
            const key = e.key;
            if (KeySlideOffset[key] === undefined) {
                return;
            }
            this.startSlideAnimation(KeySlideOffset[key]);
        };
        this.onKeyUp = (e) => {
            if (!this.isAnimating) {
                return;
            }
            if (KeySlideOffset[e.key] === undefined) {
                return;
            }
            this.stopSlideAnimation();
        };
        this.resetWidth = () => {
            this.imageWidth = this.offsetWidth;
        };
        const shadowRoot = this.attachShadow({ mode: 'open' });
        shadowRoot.appendChild(templateElement.content.cloneNode(true));
        this.firstElement = shadowRoot.getElementById('first');
        this.firstImageContainerElement = shadowRoot.getElementById('firstImageContainer');
        this.secondElement = shadowRoot.getElementById('second');
    }
    connectedCallback() {
        if (!this.hasAttribute('tabindex')) {
            this.tabIndex = TABINDEX;
        }
        this.addEventListener('dragstart', (e) => {
            e.preventDefault();
            return false;
        });
        const resizeObserver = new ResizeObserver(this.resetWidth);
        resizeObserver.observe(this);
        this.slide(0);
        this.addEventListener('keydown', this.onKeyDown);
        this.addEventListener('keyup', this.onKeyUp);
        this.addEventListener('focus', this.onFocus);
        this.addEventListener('blur', this.onBlur);
        this.addEventListener('touchstart', this.onTouchStart);
        this.addEventListener('touchmove', this.onTouchMove, {
            passive: false,
        });
        this.addEventListener('touchend', this.onTouchEnd);
        this.addEventListener('mousedown', this.onMouseDown);
        this.resetWidth();
        if (!this.classList.contains(RENDERED_CLASS)) {
            this.classList.add(RENDERED_CLASS);
        }
        if (this.querySelectorAll('[slot="before"], [slot="after"]').length > 0) {
            console.warn('<img-comparison-slider>: ' +
                'slot names "before" and "after" are deprecated and soon won\'t be supported. ' +
                'Please use slot="first" instead of slot="after", and slot="second" instead of slot="before".');
        }
    }
    disconnectedCallback() {
        if (this.transitionTimer) {
            window.clearTimeout(this.transitionTimer);
        }
    }
    reset() {
        this.exposure = 50;
        this.slide(0);
    }
    slide(increment = 0) {
        this.exposure = inBetween(this.exposure + increment, 0, 100);
        this.firstElement.style.setProperty('--exposure', `${100 - this.exposure}%`);
    }
    slideToPageX(pageX) {
        const x = pageX - this.getBoundingClientRect().left - window.scrollX;
        this.exposure = (x / this.imageWidth) * 100;
        this.slide(0);
    }
    enableTransition() {
        const transitionTime = 100;
        this.firstElement.style.setProperty('--transition-time', `${transitionTime}ms`);
        this.transitionTimer = window.setTimeout(() => {
            this.firstElement.style.setProperty('--transition-time', `0ms`);
            this.transitionTimer = null;
        }, transitionTime);
    }
    startSlideAnimation(offset) {
        let lastTimestamp = null;
        const slide = (now) => {
            if (lastTimestamp === null) {
                lastTimestamp = now;
            }
            const interval = now - lastTimestamp, distance = (interval / slideAnimationPeriod) * offset;
            this.slide(distance);
            if (this.isAnimating) {
                window.requestAnimationFrame(slide);
                lastTimestamp = now;
            }
        };
        window.requestAnimationFrame(slide);
    }
    stopSlideAnimation() {
        this.isAnimating = false;
    }
}
window.customElements.define('img-comparison-slider', HTMLImgComparisonSliderElement);
